
body {
  font-size: 18px;
  margin: 0;
}

.App {
  text-align: center;
  color: #2b2b2c;
  margin-left:auto;
  margin-right:auto;
  width:50%;
}

.itm {
  position: static;
  margin-top: 200px;
  font-size: 50px;
}

.logos {
  width: 80px;
}

.card {
  margin-top: 70px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.3);
  padding-top: 5px;
  padding-bottom: 10px;
  border-radius: 5px;
  margin-left:auto;
  margin-right:auto;
  width:300px;
}

.card-info {
  margin-left:auto;
  margin-right:auto;
}

.Info {
  line-height: 1.6;
  text-align: justify;
  margin-top: 50px;
  margin-left:auto;
  margin-right:auto;
  overflow: hidden; 
  display:inline-block;
  text-justify: inter-word;
}

.List {
  line-height: 1.6;
  text-align: left;
  margin-top: 50px;
  margin-left:auto;
  margin-right:auto;
}

.linkedin-logo {
  margin-bottom: 5px;
}

a {
  margin-left: 10px;
}

@media only screen and (min-width: 320px) {
  .App{
    width: 90%;
  }
}

@media only screen and (min-width: 768px) {
  .App{
    width: 50%;
  }
}